import api from "./api";
import TokenService from "./token.service";
import { database, authentication } from "../config/firebase-config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { generateMessage } from "./firebaseMessage";

let response = {
  status: null,
  data: null,
  message: "",
};

export const loginAsync = async ({ email, password }) => {
  try {
    const signinResponse = await signInWithEmailAndPassword(
      authentication,
      email,
      password
    );
    response = {
      status: 200,
      data: signinResponse.user.stsTokenManager,
      message: t("message_sign_in_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 400,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const fetchUserProfileAsync = async (uid) => {
  try {
    //after successful login user's profile will be fetched using "user_id" from users collection
    const docRef = doc(database, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();

      if (userData?.role?.roleName !== "Admin") {
        //once user data fetched from users collection...
        //we need to fetch role object from roles collection to get updated roles and rights info
        const roleRef = collection(database, "roles");
        const q = query(
          roleRef,
          where("createdBy", "==", String(userData.role?.createdBy)),
          where("roleName", "==", String(userData.role?.roleName))
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          delete userData.role;
          querySnapshot.forEach((doc) => {
            userData.role = { ...doc.data() };
          });
        }
      }

      if (userData.role?.roleName == "Admin") {
        delete userData.role.permissions;
        //this if statement is called when user role is Admin
        userData.role.permissions = [
          "READ_DASHBOARD",
          "UPDATE_COMPANY_PROFILE",
          "READ_CUSTOMER",
          "CREATE_CUSTOMER",
          "UPDATE_CUSTOMER",
          "DELETE_CUSTOMER",
          "READ_QUOTATION",
          "CREATE_QUOTATION",
          "UPDATE_QUOTATION",
          "DELETE_QUOTATION",
          "READ_INVOICE",
          "CREATE_INVOICE",
          "UPDATE_INVOICE",
          "DELETE_INVOICE",
          "READ_USER_MANAGEMENT",
          "CREATE_USER_MANAGEMENT",
          "UPDATE_USER_MANAGEMENT",
          "DELETE_USER_MANAGEMENT",
          "READ_ROLE",
          "CREATE_ROLE",
          "UPDATE_ROLE",
          "DELETE_ROLE",
          "READ_MASTER_OPTION",
          "CREATE_MASTER_OPTION",
          "UPDATE_MASTER_OPTION",
          "DELETE_MASTER_OPTION",
          "READ_CODE_SETUP",
          "CREATE_CODE_SETUP",
          "UPDATE_CODE_SETUP",
          "DELETE_CODE_SETUP",
          "READ_REPORT",
          "READ_PROJECT_MANAGEMENT",
          "READ_RECRUIT",
          "CREATE_RECRUIT",
          "UPDATE_RECRUIT",
          "DELETE_RECRUIT",
          "READ_EMPLOYEE",
          "CREATE_EMPLOYEE",
          "UPDATE_EMPLOYEE",
          "DELETE_EMPLOYEE",
          "READ_HR_DEPARTMENT",
          "READ_PROJECT",
          "CREATE_PROJECT",
          "UPDATE_PROJECT",
          "DELETE_PROJECT",
          "READ_TICKET",
          "CREATE_TICKET",
          "UPDATE_TICKET",
          "DELETE_TICKET",
          "READ_MASTERS",
        ];
      }

      response = {
        status: 200,
        userInfo: { ...userData },
        message: t("message_fetch_profile_successful"),
      };
    } else {
      response = {
        status: 401,
        userInfo: null,
        message: t("message_fetch_profile_failed"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};

export const forgotPasswordAsync = async (email) => {
  try {
    await sendPasswordResetEmail(authentication, email);
    response = {
      status: 200,
      message: t("message_link_sent"),
    };
    return response;
  } catch (error) {
    let message = error.code.split("/")[1];
    // let message = generateMessage(error.code);
    response = {
      status: 400,
      message: message || error.message,
    };
    return response;
  }
};

export const resetPasswordAsync = async (token, password) =>
  await api
    .post(`/auth/resetPassword/${token}`, {
      password: password,
      confirmPassword: password,
    })
    .then((response) => response.data)
    .catch((err) => err);

export const verifyUserAsync = async (token) =>
  await api
    .get(`/auth/verifyEmail/${token}`)
    .then((response) => response.data)
    .catch((err) => err);

export const registerUserAsync = async (request) => {
  const { email, password } = request;
  try {
    const createUserResponse = await createUserWithEmailAndPassword(
      authentication,
      email,
      password
    );
    delete request.password;
    let userId = createUserResponse.user.uid;
    await setDoc(doc(database, "users", createUserResponse.user.uid), {
      ...request,
      userId: userId,
      createdAt: new Date(),
    });
    response = {
      status: 200,
      message: t("message_registration_successful"),
    };
    return response;
  } catch (error) {
    let message = generateMessage(error.code);

    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
export const logout = () => TokenService.removeUser();
